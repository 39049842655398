<template>
    <div class="p-2">
        <b-breadcrumb class="mb-2" :items="breadcrumbs"></b-breadcrumb>
        <b-card>
            <application-detail-loader :reload-counter="reloadCounter" :application-id="this.$route.params.applicationId"></application-detail-loader>
        </b-card>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false"><b-icon-x-circle></b-icon-x-circle></a>
            </div>
                <router-view></router-view>
        </b-modal>
    </div>
</template>

<script>

    import ApplicationDetailLoader from "../../components/applications/ApplicationDetailLoader";
    export default {
        name: "application_detail",
        components: {ApplicationDetailLoader},
        data: function() {
            return {
                reloadCounter : 1,
                showModal : false,
                breadcrumbs : [
                    {
                        text: 'Dashboard',
                        to: {'name' : 'dashboard'}
                    },
                    {
                        text: 'Applications',
                        to: {'name' : 'applications'}
                    },
                    {
                        text: 'View application',
                        active: true
                    }
                ]
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if(newVal.params.data_updated !== undefined) {

                    }
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    if(this.$router.currentRoute.name !== 'applicationDetail') {
                        this.$router.replace({'name': 'applicationDetail'});
                    }
                    this.reloadCounter++;
                }
            }
        },
        methods : {

        }

    }
</script>
