<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div :reload="reloadCounter">
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && application != null" class="row">
            <div class="col-2 position-relative">
                <image-view class="w-100 img-thumbnail" :src="application.candidate_image_upload_id" size="medium"/>
            </div>
            <div class="col-10">
                <h3 class="mb-2 d-flex align-items-center">
                    <div class="flex-fill d-flex align-items-center">
                        <span>{{ application.first_name }} {{ application.middle_names }} {{ application.surname }}</span>
                        <span class="h6 m-0 ml-2">
                        <b-badge variant="info">
                            Ref: {{ application.job_ref }}
                        </b-badge>
                            <b-badge v-if="isArchived" class="m-1 text-uppercase font-weight-bold pr-2"
                                     style="font-size:12px;" variant="light">
                        <b-icon icon="folder"></b-icon>
                        Archived
                    </b-badge>
                        <application-status-badge v-else class="ml-2"
                                                  :status="application.status"></application-status-badge>
                    </span>
                    </div>
                    <application-action-dropdown id="quick-action-detail"
                            :exclude-items="['users']"
                            v-on:delete="loadApplication" class="flex-wrap" :item="application"
                                                 ></application-action-dropdown>
                </h3>

                <div class="px-1 mb-2">
                    <div class="mb-1">
                        <strong>ID :</strong> {{ application.ref }}
                    </div>
                    <div class="mb-1">
                        <strong>Dob :</strong> {{ dateToNice(application.dob) }}
                    </div>
                    <div class="mb-1">
                        <strong>Nationality :</strong> {{ application.nationality != null ? application.nationality.name : "Not set" }}
                    </div>
                    <div v-if="application.upload_method !== null" class="mb-1">
                        <strong>Uploaded by :</strong>
                        <span class="ml-1" v-if="application.upload_method == 'mobile'">
                            <b-icon-phone></b-icon-phone>Mobile app
                        </span>
                        <span class="ml-1" v-else>
                            <b-icon-laptop></b-icon-laptop>Web app
                        </span>
                    </div>
                    <div class="mb-1" v-if="application.notes != null && application.notes.length > 0">
                        <strong>Notes :</strong> {{ application.notes }}
                    </div>
                </div>

                <div>
                    <b-badge class="mr-1 mb-1" variant="light" v-for="(tag,tagKey) in application.tags"
                             v-bind:key="tag.id">{{
                        tag.tag_type.name }} : {{ tag.tag_value }}
                    </b-badge>
                </div>

                <h5 class="mt-4 border-bottom pb-2 mb-3">
                    Application documents
                </h5>

                <div v-if="application.captures != null && application.captures.length > 0">
                    <application-document-cell
                            :secondary-image="application.candidate_image_upload_id"
                            class="mb-2" v-for="(doc,docKey) in application.captures" v-bind:key="doc.id"
                            :item="doc">
                    </application-document-cell>
                </div>
                <div v-else>
                    No documents uploaded
                </div>

                <div v-if="application.name_change_image != null">
                    <div class="d-flex p-2 border rounded">
                        <div style="width: 150px" class="flex-wrap">
                            <image-view class="w-100 img-thumbnail" :src="application.name_change_image.id"/>
                        </div>
                        <div class="flex-fill p-3">
                            <h5>Document explaining any name differences between the captured documents or the
                                information
                                supplied</h5>
                        </div>
                    </div>
                </div>

                <div v-if="this.$store.state.user.organisation.doc_check_mode !== 'off'">
                    <h5 class="mt-4 border-bottom pb-2 mb-3">
                        Automated document checks
                    </h5>
                    <div v-if="documentsSupportMrz">
                        <div class="border rounded p-3"
                             v-if="application.doc_checks != null && application.doc_checks.length > 0">
                            <div v-for="(check,checkKey) in  application.doc_checks" v-bind:key="check.id">
                                <div class="d-flex align-items-center">
                                    <b-spinner variant="primary" small v-if="check.rag === 'WAITING'"
                                               class="flex-wrap mr-3"></b-spinner>
                                    <div class="flex-fill">
                                        <h6 class="mb-0">{{ check.created_by == null ?
                                            "Automated document check requested" :
                                            "Manual document check requested" }}</h6>
                                        <div>
                                            <b-badge :variant="docCheckVariant(check)">{{ docCheckWording(check) }}
                                            </b-badge>
                                        </div>
                                    </div>
                                </div>

                                <hr/>

                                <div class="d-flex mt-3 align-items-center"
                                     v-for="(alert,alertKey) in parseDocCheck(check)"
                                     v-bind:key="'doc_check_'+alertKey">
                                    <b-badge v-if="alert.contegoRuleId !== 0" class="flex-wrap mr-2"
                                             :variant="alert.score === 100 ? 'danger' : 'success'">
                                        {{ alert.score === 100 ? 'FAIL' : 'PASS' }}
                                    </b-badge>
                                    <b-badge v-else class="flex-wrap mr-2" variant="info">
                                        INFO
                                    </b-badge>
                                    <div class="flex-fill">
                                        {{ alert.message }}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-else>
                            No document checks for this application yet
                        </div>

                        <b-button id="document-check" :disabled="docCheckLoading" class="mt-3" @click="requestDocCheck"
                                  v-if="canRunDocCheck" variant="outline-primary">
                            <b-spinner variant="primary" small type="grow" v-if="docCheckLoading"></b-spinner>
                            {{ docCheckLoading ? "Working on it, won't be long" : (application.doc_checks != null &&
                            application.doc_checks.length > 0 ? "Re-run document check" : "Run document check") }}
                        </b-button>
                        <error-display v-model="docCheckErrors" ident="doc_check"></error-display>
                    </div>
                    <div v-else>
                        No documents within this application are suitable for automated document checks
                    </div>

                </div>

                <h5 class="mt-4 border-bottom pb-2 mb-3">
                    Application questions
                </h5>

                <div v-for="(question,questionKey) in application.questions.filter((obj) => { return obj.question != null; })" v-bind:key="question.id"
                     class="d-flex align-items-center mb-2">
                    <b-icon-x class="h4" variant="danger" v-if="question.answer === 'no'"></b-icon-x>
                    <b-icon-check class="h4" variant="success" v-if="question.answer === 'yes'"></b-icon-check>
                    <span class="flex-fill ml-2">{{  question.question.question }}</span>
                </div>

                <div v-if="application.additional_documents.length > 0">
                    <h5 class="mt-4 border-bottom pb-2">
                        Additional documents
                    </h5>
                    <additional-document-cell class="mb-2" v-for="(doc,docKey) in application.additional_documents"
                                              v-bind:key="doc.id"
                                              :item="doc">
                    </additional-document-cell>
                </div>

                <h5 class="mt-4 border-bottom pb-2">
                    Application history
                </h5>
                <div>
                    <b-table bordered striped hover :items="application.logs"
                             :fields="['action','notes','user_name','created_at']">
                        <template v-slot:cell(action)="data">
                            {{ actionToText(data.value) }}
                        </template>
                        <template v-slot:cell(notes)="data">
                            <div v-html="data.value">
                                {{ actionShouldShowNotes(data.item.action) ? data.value : "" }}
                            </div>
                        </template>
                    </b-table>
                </div>

                <div v-if="application.status === 'flagged' && $store.state.user.user_type === 'supervisor' && !isArchived">
                    <h5 class="mt-4 border-bottom pb-2 mb-3">
                        Application actions
                    </h5>
                    <div>
                        <b-form-group>
                            <b-form-radio-group
                                    name="radio-inline"
                                    size="lg"
                            >
                                <b-form-radio v-model="approvalStatus" name="applications" id="approve-application" value="approved">Approve this application</b-form-radio>
                                <b-form-radio v-model="approvalStatus" name="applications" id="reject-application" value="rejected">Reject this application</b-form-radio>
                                <b-form-radio v-model="approvalStatus" name="applications" id="archive-application" value="archived">Archive this application</b-form-radio>

                            </b-form-radio-group>
                        </b-form-group>

                        <div v-if="approvalStatus != null">
                            <b-form-group v-if="approvalStatus !== 'archived'">
                                <b-form-textarea
                                        id="approvalNotes"
                                        v-model="approvalNotes"
                                        :placeholder=" approvalStatus === 'rejected' ? 'Enter reasons why this application was rejected' : 'Any additional notes'"
                                        rows="3"
                                        max-rows="6"
                                ></b-form-textarea>
                                <error-display v-model="approvalErrors" ident="notes"></error-display>
                            </b-form-group>
                            <b-form-group v-if="this.$store.getters.isSupervisor || this.$store.getters.isAdmin">
                                <b-button @click="completeApplication"
                                          :variant="approvalStatus === 'rejected' ? 'danger' : ( approvalStatus === 'approved' ? 'success' : 'secondary')">
                                    <b-spinner variant="white" small type="grow" v-if="approvalLoading"></b-spinner>
                                    Confirm and {{ approvalStatus === 'rejected' ? 'reject' : ( approvalStatus ===
                                    'approved' ? 'approve' : 'archive') }} this
                                    application
                                </b-button>
                            </b-form-group>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>

    import {getResource, saveResource} from "../../modules/api/methods";
    import ApplicationStatusBadge from "./ApplicationStatusBadge";
    import {applicationDetail, applicationDocCheck, applicationStatus, getUpload} from "../../modules/api/endpoints";
    import ApplicationDocumentCell from "./ApplicationDocumentCell";
    import moment from 'moment';
    import _ from 'lodash';
    import {
        applicationActionShouldShowNotes,
        applicationActionToText,
        containsErrors
    } from "../../modules/helpers/helpers";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import AdditionalDocumentCell from "../applications/AdditionalDocumentCell";
    import ApplicationActionDropdown from "./ApplicationActionDropdown";
    import ImageView from "../web_app/ImageView";

    export default {
        name: "ApplicationDetailLoader",
        props: {
            applicationId: null,
            reloadCounter: 0,
        },
        components: {
            'image-view': ImageView,
            ApplicationActionDropdown,
            AdditionalDocumentCell, ErrorDisplay, ApplicationDocumentCell, ApplicationStatusBadge
        },
        data: function () {
            return {
                approvalLoading: false,
                approvalNotes: null,
                approvalStatus: null,
                approvalErrors: {},
                approvalOptions: [
                    {text: 'Approve this application', value: 'approved'},
                    {text: 'Reject this application', value: 'rejected'},
                    {text: 'Archive this application', value: 'archived'},
                ],
                isLoading: false,
                application: null,
                docCheckLoading: false,
                docCheckErrors: {}
            }
        },
        watch: {
            reloadCounter: function (newVal, oldVal) {
                console.log("ITS CHANGED");
                this.loadApplication();
            }
        },
        computed: {
            isArchived: function () {
                return this.application.archived_at != null
            },
            documentsSupportMrz: function () {
                var support = false;
                _.each(this.application.captures, function (capture) {
                    if (capture.document_type_capture != null && capture.document_type_capture.machine_readable == 1) support = true;
                });
                return support;
            },
            canRunDocCheck: function () {
                let docMode = this.$store.state.user.organisation.doc_check_mode;
                var docRunning = false;
                _.each(this.application.doc_checks, function (doc) {
                    if (doc.rag === 'WAITING') docRunning = true;
                });
                if (docRunning) return false;
                if (docMode === 'off') {
                    return false;
                } else if (docMode === 'on_upload' || docMode === 'on_request' || (docMode === 'on_request_supervisor' && (this.$store.getters.isSupervisor || this.$store.getters.isAdmin))) {
                    return true;
                } else if (docMode === 'on_approve' && this.application.status === 'approved') {
                    return true;
                }
                return false;
            },
        },
        created() {
            this.loadApplication();
        },
        methods: {
            completeApplication: function () {


                if(this.approvalStatus === 'archived') {
                    this.batchAction('archive');
                    return;
                }

                this.approvalLoading = true;

                var postData = {
                    status: this.approvalStatus,
                    notes: this.approvalNotes
                }


                saveResource(applicationStatus(this.application.id), postData).then(resp => {
                    this.application = resp.data.success.data;
                    this.$bvToast.toast('The application has been ' + this.approvalStatus, {
                        title: 'Application updated',
                        variant: 'success',
                        solid: false
                    })
                }).catch(error => {
                    if (containsErrors(error)) {
                        this.approvalErrors = error.data.errors;
                    } else {
                        this.$root.$children[0].handleApiError(error, this.completeApplication)
                    }
                }).finally(() => {
                    this.approvalLoading = false
                });
            },
            batchAction(action) {
                var params = {action: action, applicationId : this.application.id};
                var postData = {};
                postData.application_ids = [this.application.id];
                params.filters = postData;
                this.$router.push({name: 'applicationBatchDetail', params: {inputData : JSON.stringify(params)}}).catch(error => {})
            },
            actionShouldShowNotes: function (action) {
                return applicationActionShouldShowNotes(action)
            },
            actionToText: function (action) {
                return applicationActionToText(action)
            },
            parseDocCheck: function (check) {
                let obj = JSON.parse(check.contego_result);
                if (!obj) {
                    return [];
                }
                console.log("parse result", obj);
                return obj.alert;
            },
            requestDocCheck: function () {
                this.docCheckErrors = {};
                this.docCheckLoading = true;
                getResource(applicationDocCheck(this.application.id)).then((resp) => {
                    this.docCheckLoading = false;
                    this.application = resp.data.success.data;
                }).catch((ex) => {
                    if (containsErrors(ex)) {
                        this.docCheckErrors = ex.data.errors;
                    }
                    console.log(ex);
                    this.docCheckLoading = false;
                })
            },
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY");
            },
            docCheckVariant(docCheck) {
                if (docCheck.rag === 'WAITING') {
                    return "warning";
                } else if (docCheck.rag === 'RED') {
                    return "danger";
                } else if (docCheck.rag === 'GREEN') {
                    return "success";
                }
                return "dark";
            },
            docCheckWording(docCheck) {
                if (docCheck.rag === 'WAITING') {
                    return "Processing documents, only partial results showing, please check back later";
                } else if (docCheck.rag === 'RED') {
                    return "Documents failed automated verification";
                } else if (docCheck.rag === 'GREEN') {
                    return "Documents passed automated verification";
                }
                return "dark";
            },
            loadApplication() {
                if (this.application == null) {
                    this.isLoading = true;
                }
                getResource(applicationDetail(this.applicationId)).then((resp) => {
                    this.application = resp.data.success.data;

                }).catch((error) => {
                    this.$root.$children[0].handleApiError(error, this.loadApplication);
                }).finally(() => {
                    this.isLoading = false;
                    window.setTimeout(() => {
                        this.$root.$children[0].updateCurrentSteps(this.$router.currentRoute);
                    },500);
                });
            }
        }

    }
</script>

<style scoped>

</style>
