<template>
    <div class="d-flex p-2 border rounded">
        <div style="width: 150px" class="flex-wrap">

            <image-view class="w-100 img-thumbnail" :src="item.image_upload_id" :secondary-src="secondaryImage"/>
        </div>
        <div class="flex-fill p-3">
            <h5>{{ item.document_type_capture.name }}</h5>
            <div v-if="item.document_type_capture.expires === 1">
                {{ expiry }}
            </div>
            <div v-else>
                Expiry not required
            </div>
            <div v-if="createdAt !== null">
                {{ createdAt }}
            </div>
        </div>
    </div>
</template>
<script>

    import {getUpload} from "../../modules/api/endpoints";
    import moment from "moment";
    import ImageView from "../web_app/ImageView";

    export default {
        name: "ApplicationDocumentCell",
        components: {'image-view' : ImageView},
        props: {
            item: {},
            secondaryImage : null,
        },
        computed : {
            expiry : function() {
                return new moment(this.item.expiry).format("DD/MM/YYYY");
            },
            createdAt : function() {
                return this.item.created_at != null ? new moment(this.item.created_at).format("DD/MM/YYYY") : null;
            },
        },
        methods:{
            docThumb : function(imageUploadId) {
                return getUpload(imageUploadId, "thumb");
            },
        }

    }
</script>

<style scoped>

</style>
